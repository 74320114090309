import React, {useState} from 'react';
//import { Link } from 'gatsby';
import { navigate } from 'gatsby';
import firebase from '../components/firebase';

import Layout from '../components/layout';
import SEO from '../components/seo';

const RegisterLandingPage = () => {
  
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userPasswordConfirm, setUserPasswordConfirm] = useState('');
  const [userRole, setUserRole] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);

  const emailValid = userEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
  const passwordValid = userPassword.length > 5;
  const formInvalid = userPassword !== userPasswordConfirm ||
        !passwordValid ||
        !emailValid ||
        userLastName === '' ||
        userFirstName === '' ||
        !termsAccepted;
  const ValidationText = !passwordValid ? 'Password is too short.' : 
        userPassword !== userPasswordConfirm ? 'Passwords do not match.' : 
        !emailValid ? 'Email address is invalid.' :
        !termsAccepted ? 'Please accept terms and conditions.' :
        'Please fill out all fields in red.';  
  
  return (
    <Layout>
      <SEO title="Register" />
      <div className="container mx-auto h-full flex justify-center items-center">
        <form className="w-full max-w-lg" onSubmit={e => registerUser(e, userFirstName, userLastName, userEmail, userPassword, userRole)} >
          <div className="flex flex-wrap -mx-3 mb-3">
            <div className="w-full md:w-1/2 px-3 mb-3 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold" 
                htmlFor="grid-first-name">
                First Name
                <input value={userFirstName} onChange={e => setUserFirstName(e.target.value)}
                  id="grid-first-name" 
                  type="text" 
                  placeholder="Jane"
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${userFirstName === '' ? `border-red-500` : `` } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`} />
              </label>
            </div>

            <div className="w-full md:w-1/2 px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold" 
                htmlFor="grid-last-name">
                Last Name
                <input value={userLastName} onChange={e => setUserLastName(e.target.value)}      
                id="grid-last-name" 
                type="text" 
                placeholder="Doe" 
                className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${userLastName === '' ? `border-red-500` : `` } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`} />
              </label>
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-3">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold" 
                htmlFor="grid-email">
                Email
                <input value={userEmail} onChange={e => setUserEmail(e.target.value)}
                id="grid-email" 
                type="email" 
                placeholder="email.me@rigabee.com" 
                className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${emailValid ? `` : `border-red-500`} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`} />
              </label>
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-3">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold" 
                htmlFor="grid-password">
                Password
                <input value={userPassword} onChange={e => setUserPassword(e.target.value)}
                id="grid-password" 
                type="password" 
                placeholder="******************"
                className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${passwordValid ? `` : `border-red-500` } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`} />
              </label>
            </div>

            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold" 
              htmlFor="grid-passwordConfirm">
                Confirm Password
                <input value={userPasswordConfirm} onChange={e => setUserPasswordConfirm(e.target.value)}
                id="grid-password" 
                type="password" 
                placeholder="******************" 
                className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${userPasswordConfirm === '' || userPassword !== userPasswordConfirm ? `border-red-500` : `` } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`} />
              </label>
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-3">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold" 
                htmlFor="grid-role">
                Select Role
                <select value={userRole} onChange={e => setUserRole(e.target.value)}
                   id="grid-role" 
                   className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                   <option>Contributor</option>
                   <option>Director</option>
                   <option>Admin</option>
                </select> 
              </label>
            </div>
          </div>

          <div className="w-full mb-3">
            <label className={`block uppercase tracking-wide ${termsAccepted ? `text-gray-700` : `text-red-500` } text-xs font-bold`}>
              <input type="checkbox" 
                onChange={e => setTermsAccepted(!termsAccepted)}
                id="grid-terms"
                className="mr-2 leading-tight" />
              I accept Terms and Conditions
            </label>
          </div>

          <label className={`${ formInvalid ? `block` : `hidden` } text-red-500 text-xs italic w-full mb-3 text-center`}>
            ** { ValidationText }
          </label>

          <div className="flex items-center justify-end">
            <button disabled={formInvalid} 
              type="submit" 
              className={`bg-blue-500 text-white font-bold py-2 px-4 rounded ${formInvalid ? `opacity-50 cursor-not-allowed` : `hover:bg-blue-700 focus:outline-none focus:shadow-outline`}`} >
              Register
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );

  async function registerUser(e, firstName, lastName, email, password, userRole) {

    e.preventDefault();
    
    try {
      if (formInvalid) {
         alert("Please fill out all fields");
      } else {
         await firebase.register(firstName, lastName, email, password, userRole)
         navigate('/emailverification/');
      }
    } catch(error) {
      alert(error.message)
    }  
  }

}

export default RegisterLandingPage;